<template>
  <div class="bloodCollection">
    <h3 style="padding-top: 0.2rem;">采血</h3>
    <div class="cx">
      <p>是否己采血</p>
      <el-radio v-model="postdata.S_OP_Json.CL_RE_BloodDraw_YesNo" label="1">是</el-radio>
      <el-radio v-model="postdata.S_OP_Json.CL_RE_BloodDraw_YesNo" label="0">否</el-radio>
    </div>
    <UPfile :type="'BloodDraw'"></UPfile>
    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postdata.S_OP_Json.CL_RE_BloodDraw_Remark">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "bloodCollection",
  data() {
    return {
      radio: '',
      postdata: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000',
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json:{
          S_OP_IO_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          CL_RE_BloodDraw_YesNo: '',
          CL_RE_BloodDraw_Remark: ''
        },
        S_OP_Model: "S_OP_BloodDraw230216",
        S_OP_Type: "S_OP_BloodDraw"
      },
    }
  },
  watch: {
    "postdata": {
      deep: true,
      handler(n, o) {
        this.$store.commit('physicianVisits/upbloodcollection', n)
      }
    }
  },
  created() {
    this.$store.commit('physicianVisits/upbloodcollection', this.postdata)
    this._http.post(this._getUrl('PS_all_Normal_getList'), {
      "token": {
        "TimeSpan": 1566827733,
        "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      SearchCondition: {
        S_OP_Type: 'S_OP_BloodDraw',
        ChooseId: this.$store.state.physicianVisits.xzConsulting
      }
    })
        .then(res => {
          if (res.GetListResult) {
            this.postdata.S_OP_Json = this._clJson(res.GetListResult[0].S_OP_Json)
          }
        })
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
.bloodCollection {
  width: 90%;
  margin: 0 auto;
}
h4 {
  text-align: left;
  padding-bottom: 20px;
}
.cx {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  p{padding-right: 30px;}
}
.bz {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
}
.bz p {padding-bottom: 10px;font-size: 17px}
</style>
